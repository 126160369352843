import { CacheConfig } from '@primer-sdk-web/utils/cacheRequest';
import type { ClientConfiguration } from '../models/ClientConfiguration';
import { retryableGet } from '../utils/http';
import { decodeClientToken } from './decodeClientToken';
import { getPrimerHeaders } from './getPrimerHeaders';
import { createAnalytics } from '@primer-sdk-web/analytics/providers/httpAnalyticsProvider';

export async function fetchClientConfiguration(
  clientToken: string,
  checkoutSessionId: string,
  cacheConfig?: CacheConfig,
) {
  const {
    accessToken,
    configurationUrl,
    analyticsUrlV2: analyticsUrl,
  } = decodeClientToken(clientToken);

  const analytics = createAnalytics({ checkoutSessionId, url: analyticsUrl });

  analytics.timerStart({ id: 'CONFIGURATION_LOADING' });

  try {
    const { data, source } = await retryableGet<ClientConfiguration>(
      `${configurationUrl}/?withDisplayMetadata=true`,
      {
        checkoutSessionId,
        headers: getPrimerHeaders(accessToken, checkoutSessionId, '2.3'),
        retryConfig: {
          enabled: true,
        },
        cacheConfig,
      },
    );

    analytics.timerEnd({
      id: 'CONFIGURATION_LOADING',
      context: { source },
    });

    return data;
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'Unknown error occurred';

    throw new Error(`Failed to initialize client: ${errorMessage}`);
  }
}
