import {
  Analytics,
  AnalyticsEvent,
  AnalyticsEventProperties,
  AnalyticsEvents,
  AnalyticsOptions,
  createAnalyticsEvent,
  TimerEventProperties,
  TimerType,
} from '../types';

const defaultUrl = process.env.PRIMER_ANALYTICS_API_URL as string;
const isPlaywrightTests =
  defaultUrl === 'https://analytics.sandbox.data.primer.io/playwright';

// Storing them in the window to avoid bigger refactors, ideally,
// this would be provided by the loader.
// This is necessary because there are 2 analytics instances,
// one for the loader and another for the core.
let storage = {
  providers: new Map(),
  timers: new Map(),
  eventsQueue: new Map(),
};
if (typeof window !== 'undefined') {
  window['__primerAnalytics__'] ??= storage;
  storage = window['__primerAnalytics__'];
}

export const providers = (): Map<string, Analytics> => storage.providers;
export const timers = (): Map<string, { start: number }> => storage.timers;
export const eventsQueue = (): Map<
  string,
  AnalyticsEvent<AnalyticsEventProperties>[]
> => storage.eventsQueue;

const maxStringLength = 1024 * 5;
const eventsBeaconTimeout = 500;
const eventsBeaconTimeoutIds = new Map<string, number>();

export function createAnalytics({
  url = defaultUrl,
  ...options
}: AnalyticsOptions) {
  // Server Side Rendering check
  if (typeof document !== 'undefined')
    document.addEventListener('visibilitychange', () => {
      // Send immediately on visibility change
      // so we don't lose events if the customer closes the checkout
      if (document.visibilityState === 'hidden') sendEvents(url, true);
    });

  const trackEvent =
    (eventType: AnalyticsEvents) =>
    async (properties: AnalyticsEventProperties) => {
      // Server Side Rendering check
      if (typeof navigator === 'undefined') return;

      const event = await createAnalyticsEvent<AnalyticsEventProperties>(
        eventType,
        properties,
        options,
      );

      const mapValue = <T>(value: T) => trimIfString(stringifyFunction(value));

      if (isPlaywrightTests) return;

      const trimmedEvent = traverseObjectWithFunction(
        event,
        mapValue,
      ) as AnalyticsEvent<AnalyticsEventProperties>;

      queueEvent(url, trimmedEvent);
    };

  const provider = {
    crashEvent: trackEvent(AnalyticsEvents.CRASH_EVENT),
    messageEvent: trackEvent(AnalyticsEvents.MESSAGE_EVENT),
    networkCallEvent: trackEvent(AnalyticsEvents.NETWORK_CALL_EVENT),
    sdkFunctionEvent: trackEvent(AnalyticsEvents.SDK_FUNCTION_EVENT),
    timerStart: ({ id, ...rest }: TimerEventProperties) => {
      const timerKey = `${options.checkoutSessionId}|${id}`;
      timers().set(timerKey, {
        start: performance?.now(),
      });
      return trackEvent(AnalyticsEvents.TIMER_EVENT)({
        ...rest,
        id,
        timerType: TimerType.START,
      });
    },
    timerEnd: ({ id, ...rest }: TimerEventProperties) => {
      const timerKey = `${options.checkoutSessionId}|${id}`;
      const { start = undefined } = timers().get(timerKey) ?? {};
      timers().delete(timerKey);
      const end = performance?.now();

      const duration = start ? end - start : undefined;

      return trackEvent(AnalyticsEvents.TIMER_EVENT)({
        ...rest,
        id,
        timerType: TimerType.END,
        duration,
      });
    },
    v1Event: trackEvent(AnalyticsEvents.V1_EVENT),
    url,
  };

  providers().set(options.checkoutSessionId, provider);

  return getAnalytics(options.checkoutSessionId);
}

export function getAnalytics(checkoutSessionId: string) {
  // Returning a proxy so we don't have to refactor the entire SDK
  // Will return the methods from the most up-to-date analytics provider
  return new Proxy(
    {},
    {
      get(_, prop) {
        const provider =
          providers().get(checkoutSessionId) ??
          createAnalytics({ checkoutSessionId });
        return provider[prop];
      },
    },
  ) as Analytics;
}

function sendEvents(url: string, immediate = false) {
  if (eventsBeaconTimeoutIds.has(url)) {
    const id = eventsBeaconTimeoutIds.get(url);
    clearTimeout(id);
    eventsBeaconTimeoutIds.delete(url);
  }

  const send = () => {
    const events = eventsQueue().get(url);
    let sent = false;
    if (events?.length) {
      try {
        sent = navigator.sendBeacon(url, JSON.stringify(events));
      } catch (error) {
        sent = true;
        console.error('Unable to access navigator', error);
      }
      if (sent) eventsQueue().delete(url);
    }
  };

  if (immediate) {
    send();
    return;
  }

  const id = setTimeout(send, eventsBeaconTimeout) as unknown as number;
  eventsBeaconTimeoutIds.set(url, id);
}

function queueEvent(
  url: string,
  event: AnalyticsEvent<AnalyticsEventProperties>,
) {
  if (!eventsQueue().has(url)) eventsQueue().set(url, []);
  const queue = eventsQueue().get(url);
  queue?.push(event);

  sendEvents(url);
}

function traverseObjectWithFunction(
  obj: AnalyticsEvent<AnalyticsEventProperties>,
  fn: <T>(value: T) => T | string,
) {
  const processValue = (value: typeof obj) =>
    typeof value === 'object' && value !== null
      ? traverseObjectWithFunction(value, fn)
      : fn(value);

  if (Array.isArray(obj)) return obj.map(processValue);

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, processValue(value)]),
  );
}

// Trim strings properties to a threshold to prevent slowdowns due to sending big
// analytics messages. The threshold is arbitrary, tune as needed.
function trimIfString<T>(value: T) {
  if (typeof value === 'string' && value.length > maxStringLength)
    return `${value.slice(0, maxStringLength)}...`;

  return value;
}

// Replace functions by their function names if possible
function stringifyFunction<T>(value: T) {
  if (typeof value === 'function') return value.name || 'function';

  return value;
}
